footer {
  margin: 0;
  padding-bottom: 2.5em;
  padding-top: 0.4em;
  height: 3.5em;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-top: 1px solid var(--footer-border-color);
  flex-shrink: 0;
  flex-grow: 0;
}

footer button {
  font-size: 110%;
  min-width: 4.5em;
  display: flex;
  justify-content: center;
}

.footer-custom-buttons {
  display: flex;
  flex-direction: row;
  height: 100%;
  flex: 1;
  justify-content: flex-start;
  padding-left: 1em;
}

.footer-custom-buttons button {
  margin: 0 0.5em;
}
