.assets-view {
  padding: 0 1em;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
}

.assets-view h3 {
  text-align: left;
  margin: 1em 1em 1em 1em;
  display: flex;
  justify-content: space-between;
}

.assets-view h3:first-child {
  margin-top: 0;
}

.assets-view-select-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 0.8em 1.8em 0.8em;
}

.assets-total-value {
  font-size: 135%;
  flex: 1;
  text-align: right;
  font-weight: bold;
}
