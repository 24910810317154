.error-container {
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.error {
  margin-bottom: 30%;
  text-align: left;
}

.error button {
  margin-right: 0.5em;
}
