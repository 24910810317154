.item {
  padding: 0.3em;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 0.3em;
}

.item:hover {
  background-color: var(--menu-button-hover-color);
}

.item-description {
  text-align: left;
  flex: 1;
  padding: 0 0.7em;
}

.item-title {
  white-space: nowrap;
  overflow: hidden;
  max-width: 70vw;
  text-overflow: ellipsis;
}

.item-subtitle {
  font-size: 75%;
  color: var(--text-dimmed-color);
}

.item-right {
  align-self: center;
  display: flex;
  font-size: 110%;
}

.item-right .chevron {
  margin: 0 0.5em;
}

.item-box {
  border-radius: 50%;
  opacity: 0.7;
  height: 1em;
  width: 1em;
  margin: 0 0.2em;
}

.item-value {
  margin-top: 0.3em;
}
