.asset-table-scroll-container {
  overflow-y: scroll;
  flex-grow: 1;
}

.assets-table {
  display: grid;
  grid-template-columns: max-content auto max-content;
  text-align: left;
  margin-top: 1em;
}

.assets-table > div {
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0.2em 0.6em;
}

.assets-table > div:nth-child(3n + 4) {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.assets-table > div:nth-child(3n + 3) {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.assets-table > div:nth-child(6n + 4),
.assets-table > div:nth-child(6n + 5),
.assets-table > div:nth-child(6n + 6) {
  background-color: var(--table-odd-row-background);
}

.assets-table .image-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.assets-table img {
  border-radius: 50%;
}

.assets-table:last-child {
  padding-bottom: 3em;
}
