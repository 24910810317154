.income-view {
  padding: 0 2.2em;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.income-table {
  display: grid;
  grid-template-columns: max-content auto auto;
  text-align: left;
  margin-top: 1em;
  width: 100%;
  row-gap: 0.8em;
  column-gap: 0.2em;
}

.income-month {
  margin: 0 auto 1.5em auto;
  font-size: 120%;
}

.income-table-divider {
  border-top: 2px solid var(--footer-border-color);
  grid-column: 1 / span 3;
}

.income-table-sum {
  justify-content: flex-end;
  grid-column: 3;
}

.income-table > div {
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.income-table > div:nth-child(3n) {
  justify-content: flex-end;
}
