.menu-button {
  font-size: 115%;
  cursor: pointer;
  color: var(--button-color);
  display: flex;
  user-select: none;
}

.menu-button-group-button .menu-button-content {
  font-size: 140%;
  padding: 0.5em 0.8em;
}

.menu-button--active {
  color: var(--bar-highlight-color);
}

.menu-button-content {
  padding: 0.8em 1.5em;
}

.menu-button-indicator {
  width: 10px;
  background-color: var(--menu-button-indicator-color);
  border-bottom: 1px solid var(--background);
}

.menu-button--active .menu-button-indicator {
  background-color: var(--bar-highlight-color);
}

.menu-button:hover {
  background-color: var(--menu-button-hover-color);
}
