.upload-dialog-container {
  position: fixed;
  z-index: 10;
  padding: 2em 1em;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.upload-dialog {
  background-color: #777777;
  padding: 0 1em;
  width: 100%;
  border-radius: 10px;
  display: grid;
  grid-template-rows: max-content 1fr max-content;
  font-size: 90%;
}

.upload-dialog-header h1, h2, h3 {
  margin: 0;
}

.upload-dialog-footer {
  display: grid;
  grid-template-columns: 50% 25% 25%;

}

.upload-dialog-header, .upload-dialog-footer {
  padding: 1em 0;
}