.item-group {
  margin: 0;
  font-size: 110%;
  font-weight: normal;
  text-align: center;
  margin-bottom: 0.5em;
  border-top: 1px solid var(--footer-border-color);
  padding-top: 1.2em;
}

.item-group:not(:first-child) {
  margin-top: 0.5em;
}
