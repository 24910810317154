.icon-button {
  position: relative;
  height: 100%;
  font-size: 100%;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.icon-button-text {
  position: absolute;
  font-size: 70%;
  top: 100%;
}

@keyframes icon-button-flash {
  0% {
    color: var(--button-flash-color);
  }
  100% {
    color: var(--button-color);
  }
}

.icon-button-flash {
  animation: icon-button-flash 0.5s ease-in;
}
