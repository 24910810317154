.transaction-details-content {
  padding: 2em 1.6em;
  display: grid;
  grid-template-columns: max-content 1fr;
  row-gap: 0.5em;
}

.transactions-details-date {
  margin-top: 0.8em;
}
