.legend {
  padding: 0em 1.2em;
  width: 90%;
  margin: 0 auto;
  max-height: 22vh;
}

.legend-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  margin-bottom: 0.2em;
}

.legend-box {
  width: 1em;
  height: 1em;
  border-radius: 50%;
}

.legend-name {
  padding: 0.1em 0.5em;
  text-align: left;
  flex: 1;
}

.legend-value {
  text-align: right;
}

.legend-value-percent {
  text-align: right;
  min-width: 4em;
}
