:root {
  --background: #fff;
  --text-color: #222;
  --text-dimmed-color: #888;

  --button-color: steelblue;
  --button-color-disabled: rgb(180, 180, 180);
  --button-flash-color: rgb(255, 121, 121);

  --menu-button-hover-color: rgba(152, 199, 236, 0.1);
  --menu-button-indicator-color: #dedede;

  --footer-border-color: rgb(227, 227, 227);
  --input-border-color: #222;

  --loading-spinner-color: rgb(125, 180, 226);
  --bank-connections-icon-border: rgba(0, 0, 0, 0.1);
  --table-odd-row-background: rgb(87, 170, 238, 0.2);
  --expandable-panel-box-shadow-color: rgba(0, 0, 0, 0.2);
  --select-border-color: #b3b3b3;

  --bar-base-color: #4f78b3;
  --line-base-color: #4f78b3;
  --bar-highlight-color: #ff7477;

  /* https://coolors.co/184468-4b9b8f-a6c58a-f3eda5-e34956-ec783f-d5d3b8-1898b5-9e7b9b-a23b72 */
  --bar-color-1: #184468;
  --bar-color-2: #4b9b8f;
  --bar-color-3: #a6c58a;
  --bar-color-4: #f3eda5;
  --bar-color-5: #e34956;
  --bar-color-5: #ec783f;
  --bar-color-6: #d5d3b8;
  --bar-color-7: #1898b5;
  --bar-color-8: #9e7b9b;
  --bar-color-9: #a23b72;
}

@media (prefers-color-scheme: dark) {
  :root {
    --background: #292929;
    --text-color: #ebebeb;
    --text-dimmed-color: #e4e4e4;
    --input-border-color: #d6d6d6;
    --footer-border-color: #707070;
    --button-color: var(--text-color);
    --bank-connections-icon-border: rgba(0, 0, 0, 0);
  }
}

html {
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: fixed;
}

body {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: "Montserrat", sans-serif;
  touch-action: manipulation;
  background-color: var(--background);
}

html {
  color: var(--text-color);
  line-height: 1.4;
  font-size: 16px;
}

#root {
  height: 100%;
}

.App {
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
}

.loading-container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.recharts-wrapper {
  margin: 0 auto;
}

.pie-chart-label {
  letter-spacing: 0.05em;
  font-size: 100%;
  cursor: pointer;
}

.chart-label-value {
  font-size: 85%;
}

.pie-slice,
.bar {
  cursor: pointer;
}

text {
  fill: var(--text-color);
}

.pie-label-bottom {
  transform: translateY(0px);
  font-size: 120%;
}

.pie-label-center {
  transform: translateY(-45px);
  font-weight: bold;
  font-size: 190%;
}

button {
  border: 0;
  border-radius: 5px;
  font-size: 120%;
  outline: 0;
  color: var(--text-color);
  padding: 0.5em 1.5em;
  color: var(--button-color);
  background: transparent;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
}

button:disabled {
  color: var(--button-color-disabled);
}

.chevron::before {
  border-style: solid;
  border-width: 0.2em 0.2em 0 0;
  content: "";
  display: inline-block;
  height: 0.45em;
  left: 0.3em;
  position: relative;
  top: 0.4em;
  transform: rotate(-45deg);
  vertical-align: top;
  width: 0.45em;
}

.chevron.right:before {
  left: 0;
  transform: rotate(45deg);
}

.chevron.bottom:before {
  top: 0;
  transform: rotate(135deg);
}

.chevron.left:before {
  left: 0;
  transform: rotate(-135deg);
}

.pie-chart-container {
  flex: 13;
  display: flex;
  flex-direction: column;
}

.pie-chart-container .legend {
  overflow-y: scroll;
}

.chart-container {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  overflow: hidden;
}

.bar-chart-container {
  flex: 7;
  display: flex;
  flex-direction: column;
}

header {
  display: flex;
  flex-direction: column;
  padding: 0 1.5em;
  padding-bottom: 0.5em;
}

header h1 {
  border-radius: 15px;
  font-size: 100%;
  text-align: left;
}

h1,
h2 {
  font-weight: normal;
}

.bankconnections-panel {
  height: 100%;
}

input[type="text"] {
  background-color: var(--background);
  color: var(--text-color);
  outline: 0;
  border: 1px solid var(--input-border-color);
  border-radius: 3px;
  padding: 0.4em 0.6em;
  font-size: 120%;
}

.input-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.input-group input {
  margin-top: 0.4em;
}

.strikethrough {
  position: relative;
}

.strikethrough:before {
  position: absolute;
  content: "";
  left: 0;
  top: 50%;
  right: 0;
  border-top: 1px solid;
  border-color: inherit;
  transform: rotate(-15deg);
}