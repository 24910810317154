.item-list {
  margin-top: 1em;
  display: flex;
  flex-direction: column;
  padding: 0 0.5em;
  font-weight: lighter;
  overflow-y: scroll;
  flex-shrink: 1;
  flex: 1;
}

.show-more-button {
  margin: 0.5em auto 1em auto;
  font-size: 120%;
}

.transactions-details-panel {
  height: 100%;
}
