.expandable-panel {
  bottom: 0;
  position: fixed;
  background-color: var(--background);
  z-index: 1;
  transition: width 0.2s;
  display: flex;
  flex-direction: column;
  text-align: left;
  box-sizing: border-box;
  overflow-x: hidden;
  box-shadow: 0 0 20px 3px var(--expandable-panel-box-shadow-color);
}

.expandable-panel-inner {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.expandable-panel.expandable-panel-left {
  left: 0;
}

.expandable-panel.expandable-panel-right {
  right: 0;
}

.expandable-panel-overlay {
  width: 100%;
  height: 100%;
  z-index: 1;
  position: fixed;
  top: 0;
  left: 0;
}

.expandable-panel-close-button {
  font-size: 3em;
  padding: 0;
  position: absolute;
  top: 0.1em;
  color: var(--text-color);
}

.expandable-panel-left .expandable-panel-close-button {
  right: 0.5em;
}

.expandable-panel-right .expandable-panel-close-button {
  left: 0.5em;
}

.expandable-panel-close-button:hover {
  color: var(--background);
}
