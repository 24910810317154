.menu-content {
  padding: 1em 0;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.menu-content ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.menu-panel {
  border-top-right-radius: 8px;
}
