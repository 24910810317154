.connections {
  margin-bottom: 2.5em;
  flex-shrink: 0;
  padding: 0em 1.5em;
}

.connections-table {
  display: grid;
  grid-template-columns: max-content auto min-content;
  column-gap: 1em;
  row-gap: 1em;
}

.connections-table div {
  display: flex;
  align-items: center;
}

.connections-table img {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  border: 1px solid var(--bank-connections-icon-border);
}
