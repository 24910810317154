.settings-view {
  padding: 1em 2em;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.settings-view > * {
  margin-bottom: 2em;
}

.settings-view button {
  border: 1px solid var(--button-color);
  border-radius: 10px;
  font-size: 120%;
}
